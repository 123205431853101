export const search_form = {
  data() {
    return {
      search: {
        path: '',
        search_type: '',
        term: "",
        submitted_term: "",
        location: "",
        latitude: null,
        longitude: null,
        sorting: null,
        search_submitted: false,
        initial_search_submitted: false,
        form_visible: true,
        within: "3",
        results: [],
        url_search_params: null,
        category_schemes: [],
        preselected_category_ids: [],
        start_date: null,
        end_date: null,
        search_url: '',
        category_id: null
      },
      expanded_category_ids: [],
      show_spinner: true
    }
  },
  methods: {
    setDates(from, to) {
      this.search.start_date = new Date().fp_incr(from);
      this.search.end_date = new Date().fp_incr(to);
    },
    setThisWeek() {
      this.search.start_date = new Date().fp_incr(0);
      this.search.end_date = new Date().fp_incr(7 - this.search.start_date.getDay());
    },
    setNextWeek() {
      this.search.start_date = new Date().fp_incr(7 - new Date().fp_incr(0).getDay() + 1);
      this.search.end_date = new Date().fp_incr(13);
    },
    toggleSearchForm() {
      this.search.form_visible = !this.search.form_visible;
      // if (this.is_mobile) {
      // }
    },
    newSearchSubmitted() {
      this.pagination.page = 1;
      this.search.search_submitted = true;
      this.search.submitted_term = this.search.term;
      this.search.initial_search_submitted = true;

      if (this.is_mobile) {
        this.search.form_visible = false;
      }
    },
    clearSearch() {
      this.search.term = "";
      this.search.submitted_term = "";
      this.search.location = "";
      this.search.latitude = null;
      this.search.longitude = null;
      this.search.sorting = null;
      this.search.search_submitted = false;
      this.search.start_date = null;
      this.search.end_date = null;
      this.search.preselected_category_ids = [];
    },
    updateUrl() {
      this.search.url_search_params = new URLSearchParams;
      this.search.url_search_params.append('page', this.pagination.page);
      this.search.url_search_params.append('per_page', this.pagination.per_page);



      if (this.search.term) {
        this.search.submitted_term = this.search.term;
      }

      this.search.url_search_params.append(`${this.search.search_type}[term]`, this.search.term);

      if (this.search.sorting) {
        this.search.url_search_params.append(`${this.search.search_type}[sorting]`, this.search.sorting);
      }

      if (this.search.category_schemes && this.search.category_schemes.length) {
        for (let category_scheme of this.search.category_schemes) {
          for (let category of category_scheme.categories) {
            if (this.search.preselected_category_ids.includes(category.id) && category.selected == false) {
              category.selected = true;
            }

            if (category.selected) {
              if (category.result_count) {
                this.expanded_category_ids.push(category_scheme.id);
              }

              if (!category_scheme.is_nested || category.subcategories.length == 0) {
                this.search.url_search_params.append(`${this.search.search_type}[category_ids][]`, category.id);
              }
            }

            if (category_scheme.is_nested && category.subcategories) {
              for (let subcategory of category.subcategories) {
                if (this.search.preselected_category_ids.includes(subcategory.id) && subcategory.selected == false) {
                  subcategory.selected = true;
                }

                if (subcategory.selected) {
                  this.search.url_search_params.append(`${this.search.search_type}[category_ids][]`, subcategory.id);
                  category.selected = true;

                  if (subcategory.result_count) {
                    this.expanded_category_ids.push(category.id);
                    this.expanded_category_ids.push(category_scheme.id);
                  }
                }
              }
            }
          }
        }

        this.expanded_category_ids = [...new Set(this.expanded_category_ids)];
      }

      this.search.preselected_category_ids = [];

      if (this.search.start_date) {
        this.search.url_search_params.append(`${this.search.search_type}[start_date]`, this.search.start_date);
      }

      if (this.search.end_date) {
        this.search.url_search_params.append(`${this.search.search_type}[end_date]`, this.search.end_date);
      }

      if (this.search.category_id) {
        this.search.url_search_params.append(`${this.search.search_type}[category_id]`, this.search.category_id);
      }

      if (this.search.location && this.search.latitude && this.search.longitude) {
        this.search.url_search_params.append(`${this.search.search_type}[within]`, this.search.within);
        this.search.url_search_params.append(`${this.search.search_type}[location]`, this.search.location);
        this.search.url_search_params.append(`${this.search.search_type}[latitude]`, this.search.latitude);
        this.search.url_search_params.append(`${this.search.search_type}[longitude]`, this.search.longitude);
      }

      let revised_search_url = `${this.search.path}?` + this.search.url_search_params.toString();



      return revised_search_url;
    },
    initialiseFromParams() {
      var url = new URL(window.location.href);
      var c = url.searchParams;

      if (c.get("page")) {
        this.pagination.page = c.get("page");
      }

      if (c.get("per_page")) {
        this.pagination.per_page = c.get("per_page");
      }

      if (c.get(`${this.search.search_type}[term]`)) {
        this.search.term = c.get(`${this.search.search_type}[term]`);
        this.search.search_submitted = true;
      }

      if (c.get("search_event[start_date]")) {
        this.search.start_date = c.get("search_event[start_date]");
        this.search.search_submitted = true;
      }

      if (c.get("search_event[end_date]")) {
        this.search.end_date = c.get("search_event[end_date]");
        this.search.search_submitted = true;
      }

      if (c.get("search_quote[start_date]")) {
        this.search.start_date = c.get("search_quote[start_date]");
        this.search.search_submitted = true;
      }

      if (c.get("search_quote[end_date]")) {
        this.search.end_date = c.get("search_quote[end_date]");
        this.search.search_submitted = true;
      }

      if (c.get(`${this.search.search_type}[within]`)) {
        this.search.within = c.get(`${this.search.search_type}[within]`);
      }

      if (c.get(`${this.search.search_type}[location]`)) {
        this.search.location = c.get(`${this.search.search_type}[location]`);
        this.search.search_submitted = true;
      }

      if (c.get(`${this.search.search_type}[latitude]`)) {
        this.search.latitude = c.get(`${this.search.search_type}[latitude]`);
      }

      if (c.get(`${this.search.search_type}[longitude]`)) {
        this.search.longitude = c.get(`${this.search.search_type}[longitude]`);
      }

      if (c.get(`${this.search.search_type}[sorting]`)) {
        this.search.sorting = c.get(`${this.search.search_type}[sorting]`);
      }

      if (c.get(`${this.search.search_type}[category_id]`)) {
        this.search.category_id = c.get(`${this.search.search_type}[category_id]`);
      }

      this.search.preselected_category_ids = c.getAll(`${this.search.search_type}[category_ids][]`).map(Number);
    },
    handleResponse(response) {
      this.search.results = response.data[`${ this.search.model }`];
      this.pagination = response.data.pagination;
      if ((!this.search.results || !this.search.results.length) && typeof ga !== 'undefined') {
        ga('send', 'event', 'Search Results', 'No Results', this.search.term, {'nonInteraction': 1});
      }
      this.search.category_schemes = response.data.category_schemes;
      this.sort_options = response.data.sort_options;
      this.show_spinner = false;
    }
  },
  computed: {
    resultCount() {
      return this.pagination.total_count;
    },
    categoryClass() {
      return `category-title--${this.categoryInfo.id}`;
    },
    categoryInfo() {
      let category_info;
      if (category_info = this.search.category_schemes[0]) {
        return category_info.categories.find(item => item.id == this.search.category_id);
      }
    },
    showSearchForm() {
      if (this.is_mobile) {
        if (this.search.form_visible) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    },
    showSaveSearchForm() {
      return this.search.search_submitted && (this.search.results && this.search.results.length) && !this.save_search.show_success_message;
    },
    searchResultsCount() {
      return this.search.results.length;
    }
  }
}
