import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import VueFlatPickr from 'vue-flatpickr-component';
import { google_places } from 'javascripts/mixins/google_places.js';
import { flatpickr_config } from 'javascripts/mixins/flatpickr_config.js';
import { pagination } from 'javascripts/mixins/pagination.js';
import { search_form } from 'javascripts/mixins/search_form.js';
import { search_filters } from 'javascripts/mixins/search_filters.js';
import { scroll_to_top } from 'javascripts/mixins/scroll_to_top.js';
import eventResult from 'javascripts/components/eventResult.js';

import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatPickr, {
  name: 'flat-pickr'
});

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("events_index")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    const Events = Vue.component('events', {
      mixins: [
        scroll_to_top,
        google_places,
        flatpickr_config,
        search_form,
        search_filters,
        pagination,
      ],
      components: {
        eventResult
      },
      props: [
        'per_page'
      ],
      data() {
        return {
          favourites: [],
          favourites_enabled: false,
          favourites_apply_to: [],
          event_labels: null,
          show_filter: true,
          filter_status: "",
          subsite: null,
          search: {
            model: 'events',
            path: 'events',
            search_type: 'search_event',
            search_url: "/events?page=1&per_page=10",
            search_submitted: true
          },
          show_error: false,
          refreshing_filters: true,
          is_mobile: window.innerWidth <= 922
        };
      },
      methods: {
        triggerUpdateUrl() {
          if (this.search.search_url != this.updateUrl()) {
            this.search.search_url = this.updateUrl();

            router.push({
              path: this.search.search_url
            });
          }
        },
        loadData() {
          this.search.results = [];
          this.triggerUpdateUrl();
          this.show_spinner = true;

          axios.get(`${ this.search.path }.json`, {
            params: this.search.url_search_params
          }).then((response) => {
            this.handleResponse(response);
            this.refreshing_filters = false;
            this.event_labels = response.data.event_labels;
            this.favourites = response.data['favourites'];
            this.favourites_enabled = response.data.favourites_enabled;
            this.favourites_apply_to = response.data.favourites_apply_to;
          });
        },
        newSearch(refresh_filters) {
          this.show_error = false;

          if (this.search.search_url != this.updateUrl()) {
            this.search.form_visible = !this.search.form_visible;

            if (refresh_filters) {
              this.refreshing_filters = refresh_filters;
              this.clearCategories();
            }

            if (this.show_filter) {
              this.toggleFilter();
            }

            if (this.is_mobile) {
              this.scrollToTop();
            }

            this.newSearchSubmitted();
            this.triggerUpdateUrl();
          } else {
            this.show_error = true;
          }
        },
        clearAll() {
          this.show_error = false;
          this.clearSearch();
          this.clearCatsAndScrollTop();
        },
      },
      computed: {
        documentBody() {
          return document.getElementById('js-body');
        },
        itemIsFavourable() {
          return this.favourites_enabled && this.favourites_apply_to.includes("Events");
        }
      },
      mounted() {
        // Initialise local variables from set params
        //this.subsite = document.getElementById("events_index").getAttribute('data-subsite');
        this.initialiseFromParams();
        axios.get(`${ this.search.path }.json`, {
          params: {
            per_page: 1
          }
        }).then((response) => {
          this.search.category_schemes = response.data.category_schemes;
        }).then(() => {
          // Now load full data for search
          this.loadData();
        })

        if (this.is_mobile) {
          this.show_filter = false;
        }
      },
      created() {
        this.search.path = window.location.pathname;
        addEventListener('resize', () => {
          this.is_mobile = innerWidth <= 992;

          if (this.is_mobile) {
            this.show_filter = false;
          } else {
            this.show_filter = true;
          }
        })
      },
      watch: {
        '$route'(to, from) {
          this.clearSearch();
          this.initialiseFromParams();

          if (to != "/events?page=1&per_page=10") {
            this.search.search_submitted = true;
          };
          
          this.loadData();
        }
      }
    })

    const router = new VueRouter({
      mode: 'history',
      routes: [{
        path: '/events/*',
        component: Events
      }]
    })

    new Vue({
      router
    }).$mount('#events_index');
  }
});
